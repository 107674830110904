import * as React from 'react'

import Graph from '../assets/images/icons/dark/graph.inline.svg'
import WorldLocations from '../assets/images/icons/dark/world-locations.inline.svg'
import CertificateTick from '../assets/images/icons/dark/certificate-tick.inline.svg'
import MessagePeople from '../assets/images/icons/dark/message-people.inline.svg'
import TimeIsMoney from '../assets/images/icons/dark/time-money.inline.svg'
import orderDarkIcon from '../assets/images/icons/dark/order-dark.inline.svg'
import Illustration01 from '../assets/images/svg/marketing-and-sales.svg'
import Illustration02 from '../assets/images/svg/send-proposals-close-orders-confidently.svg'
import Illustration03 from '../assets/images/svg/digitalizing-textile-supply-chain.svg'
import ArrowRightAltSharpIcon from '@mui/icons-material/ArrowRightAltSharp'
// import Image01 from '../assets/images/general-page/find-a-new-supplier.png'

export const forSuppliersContent = {
  companyInfo: {
    title: 'Case Studies',
    mode: 'dark',
    button: {
      text: 'All Case Studies',
      href: '/case-study/',
      size: 'small',
      color: 'white',
      variant: 'outlined',
      style: { marginTop: 64 },
      endIcon: <ArrowRightAltSharpIcon sx={{ marginTop: '1px' }} />,
    },
    slides: [
      {
        name: 'Ningbo New Time Industry',
        location: 'Ningbo, China',
        type: 'Apparel Manufacturer',
        employees: '300 Employees',
        items: [
          { icon: MessagePeople, title: '2 New Buyer Countries', description: 'Italy and Portugal' },
          { icon: TimeIsMoney, title: '80% of', description: 'costs and time reduction' },
          { icon: CertificateTick, title: 'PO of 500–1000', description: 'pieces with a good unit price' },
        ],
      },
      {
        name: 'Tricothius',
        location: 'Barcelos, Portugal',
        type: 'Apparel Manufacturer',
        employees: '12 Employees',
        items: [
          { icon: TimeIsMoney, title: '150000€', description: 'order value' },
          { icon: TimeIsMoney, title: 'Surpassed ROI', description: 'with 5 orders' },
          { icon: WorldLocations, title: 'Got New Customers', description: 'in the US and Germany' },
        ],
      },
      {
        name: 'TurkishMade.co',
        location: 'Istanbul, Turkey',
        type: 'Apparel Manufacturer',
        employees: '22 Employees',
        items: [
          { icon: MessagePeople, title: 'PO Completed', description: 'with 6 buyers' },
          { icon: TimeIsMoney, title: 'Saved 150k TRY per year', description: 'from the marketing expenses' },
          { icon: Graph, title: 'Using Foursource', description: 'for sales and lead generation' },
        ],
      },
      {
        name: 'Abilheira & Cunha - Têxteis, Lda',
        location: 'Portugal',
        type: 'Apparel Manufacturer',
        employees: '32 Employees',
        items: [
          { icon: TimeIsMoney, title: '300k EUR', description: 'of total order value using Foursource.' },
          { icon: WorldLocations, title: '7 new buyer countries', description: 'Netherlands, Finland, Saudi Arabia, Germany, Denmark, France, Austria.' },
          { icon: orderDarkIcon, title: '25 Completed orders', description: 'in total, in two years.' },
        ],
      },
      {
        name: 'BLESS Internacional',
        location: 'Guimarães, Portugal',
        type: 'Apparel Manufacturer',
        employees: '16 Employees',
        items: [
          { icon: WorldLocations, title: '3 New Buyers', description: 'Switzerland, Germany, The Netherlands' },
          { icon: TimeIsMoney, title: 'Completed PO', description: 'with the value of 50K EUR' },
          { icon: Graph, title: 'Higher Company Exposure' },
        ],
      },
    ],
  },
  columns1: {
    direction: 'reverse',
    mode: 'dark',
    sizes: [6, 5],
    heading: {
      title: 'Save Time. Increase Profit.',
      subhead: 'For Suppliers',
    },
    illustration: {
      src: Illustration01,
      alt: 'Marketing and Sales',
    },
    list: [
      'Promote your company and products to a verified network',
      'Find and contact buyers and vendors from your desk',
      'Respond to sourcing projects and send quotes',
      'Create business opportunities without middlemen',
    ],
    buttons: [
      {
        text: 'Get started',
        href: 'SIGNUP',
        color: 'primary',
        size: 'small',
      },
      {
        text: 'Book a demo',
        href: 'https://calendly.com/yarabanyatta/30min',
        color: 'white',
        size: 'small',
        variant: 'outlined',
        target: '_blank',
        rel: 'noopener',
      },
    ],
  },
  columns2: {
    direction: 'default',
    mode: 'dark',
    sizes: [6, 5],
    heading: {
      title: 'Send proposals. Close orders confidently.',
      
    },
    illustration: {
      src: Illustration02,
      alt: 'Send proposals. Close orders confidently.',
    },
    list: [
      'Handle incoming orders, efficiently managing details, and submitting proposals to buyers.',
      'Present a competitive price proposal and receive order confirmation.',
      'Move to payment details. Make secure transactions using our escrow service and e-wallets.',
      'Start production, meeting specified requirements and readying them for delivery.',
    ],
    buttons: [
      {
        text: 'Get started',
        href: 'SIGNUP',
        color: 'primary',
        size: 'small',
      },
    ],
  },
  columns3: {
    mode: 'dark',
    sizes: [6, 5],
    heading: {
      title: 'The Largest Market Network For The Textitle Industry',
      subhead: 'Join the Network',
    },
    illustration: {
      src: Illustration03,
      alt: 'Digitalizing Textile Supply Chain',
    },
    list: [
      '+45,000 registered members',
      '+120 countries represented',
      '+100 million of pieces requested',
      'Gain market insights and react to changing conditions faster',
    ],
    buttons: [
      {
        text: 'Get started',
        href: 'SIGNUP',
        color: 'primary',
        size: 'small',
      },
      {
        text: 'Book a demo',
        href: 'https://calendly.com/yarabanyatta/30min',
        color: 'white',
        size: 'small',
        variant: 'outlined',
        target: '_blank',
        rel: 'noopener',
      },
    ],
  },
  plans: {
    mode: 'dark',
    title: 'Find The Right Plan For Your Business',
    subhead: 'Membership Plans',
    description: 'Get plans for every stage of your business growth',
    group: 'suppliers',
  },
}
